import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CabinetOrder, User } from '@app-core/entities';
import { PaymentTypeEnum } from '@app-core/enums/payment-type.enum';
import { OrderFilters, Pagination, PayParameters } from '@app-core/models';
import { CabinetApiService } from '@app-core/api-services';
import { PayService } from '@app-core/services/pay.service';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { toCamel } from '@shared/utils';
import moment from 'moment';
import { MessageService } from 'primeng/api';

import { catchError, from, mergeMap, of, switchMap, tap } from 'rxjs';
import {
  AddService,
  ChangePasswordAction,
  ChangeUser,
  FindOrders, GetUserInfo,
  InitProfileAction,
  PayAction,
  PayActionSuccess,
} from './profile.actions';
import { PROFILE_STATE_INIT, ProfileStateModel } from './profile.model';
import {LoginComplete} from "../../auth/state";

@State<ProfileStateModel>({
  name: 'profile',
  defaults: PROFILE_STATE_INIT,
})
@Injectable()
export class ProfileState {
  @Selector()
  public static entities({ items }: ProfileStateModel): CabinetOrder[] | null {
    return items;
  }
  @Selector()
  public static archiveEntities({
    items,
  }: ProfileStateModel): CabinetOrder[] | null {
    return items
      ? items.filter((el) => {
          if (el.returnFlight) {
            return moment(new Date()).isAfter(el.returnFlight.landingDateTime);
          } else {
            return moment(new Date()).isAfter(el.directFlight.landingDateTime);
          }
        })
      : items;
  }
  @Selector()
  public static activeEntities({
    items,
  }: ProfileStateModel): CabinetOrder[] | null {
    return items
      ? items.filter((el) => {
          if (el.returnFlight) {
            return moment(new Date()).isBefore(el.returnFlight.landingDateTime);
          } else {
            return moment(new Date()).isBefore(el.directFlight.landingDateTime);
          }
        })
      : items;
  }
  @Selector()
  public static user(ctx: ProfileStateModel): User | null {
    return ctx.user;
  }
  @Selector()
  public static loaded(ctx: ProfileStateModel): boolean {
    return ctx.loaded;
  }

  @Selector()
  public static totalCount(ctx: ProfileStateModel): number {
    return ctx.totalCount;
  }

  @Selector()
  public static pagination(ctx: ProfileStateModel): Pagination {
    return {
      first: ctx.pageNumber * ctx.pageSize,
      rows: ctx.pageSize,
    };
  }

  @Selector()
  public static filters(ctx: ProfileStateModel): OrderFilters {
    return ctx.filters;
  }
  @Selector()
  static activeOrder({ items }: ProfileStateModel) {
    return (orderId: number) => {
      const order = items
        ? items.find((order) => order.orderId === orderId)
        : items;
      return order || null;
    };
  }
  constructor(
    private readonly cabinetApiService: CabinetApiService,
    @Inject(DOCUMENT) private document: Document,
    private readonly payService: PayService,
    private messageService: MessageService
  ) {}
  @Action(PayAction)
  public pay(
    { getState, patchState, dispatch }: StateContext<ProfileStateModel>,
    { orderId, method, currency }: PayAction
  ) {
    return this.cabinetApiService
      .toPay(orderId.toString(), method, currency)
      .pipe(
        switchMap((response) => {
          const normalizeResponse = (value: string): PayParameters => {
            return value.includes('u0022') ? toCamel(JSON.parse(value)) : value;
          };
          const isLink =
            response.paymentTypeId !== PaymentTypeEnum.CloudPayments;
          const payParameters = !isLink
            ? normalizeResponse(response.redirectTo)
            : null;
          return from(
            this.payService.pay({
              payParameters,
              redirectTo: isLink ? response.redirectTo : null,
            })
          );
        }),
        switchMap((value) => dispatch([new PayActionSuccess()])),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Service Message',
            detail: err.error.errorMessage ||  'ERRORS.PAY',
          });
          return of();
        })
      );
  }

  @Action(PayActionSuccess)
  public payActionSuccess({ dispatch }: StateContext<ProfileStateModel>) {
    dispatch([new InitProfileAction()]);
  }
  @Action(LoginComplete)
  public loginComplete({dispatch}:StateContext<ProfileStateModel>){
    dispatch([new GetUserInfo()])
  }

  @Action(InitProfileAction, { cancelUncompleted: true })
  public init({
    getState,
    patchState,
    dispatch,
  }: StateContext<ProfileStateModel>) {
    return this.cabinetApiService.getUserInfo().pipe(
      tap((response) => patchState({ user: response })),
      mergeMap(() => dispatch(FindOrders))
    );
  }
  @Action(GetUserInfo)
  public getUserInfo(
    {  patchState }: StateContext<ProfileStateModel>
  ) {
    return this.cabinetApiService.getUserInfo().pipe(
      tap((response) => patchState({ user: response }))
    );
  }
  @Action(ChangeUser)
  public changeUserInfo(
    { getState, patchState }: StateContext<ProfileStateModel>,
    { payload }: ChangeUser
  ) {
    const { user } = getState();
    return this.cabinetApiService.changeUserInfo(payload).pipe(
      tap(() => {
        const { phoneNumber, ...main } = payload;
        return patchState({ user: { ...user!, ...main, phone: phoneNumber } });
      })
    );
  }
  @Action(ChangePasswordAction)
  public changePassword(
    { getState, patchState, dispatch }: StateContext<ProfileStateModel>,
    { payload }: ChangePasswordAction
  ) {
    const { user } = getState();
    return this.cabinetApiService.patchPassword(payload).pipe(
      catchError((err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Service Message',
          detail: err.error,
        });
        return of();
      })
    );
  }
  @Action(FindOrders)
  public find({ getState, patchState }: StateContext<ProfileStateModel>) {
    const { user } = getState();

    if (user?.id) {
      return this.cabinetApiService.getOrders(user?.id).pipe(
        tap((responseModel: CabinetOrder[]) =>
          patchState({
            items: responseModel,

            loaded: true,
          })
        )
      );
    } else {
      return;
    }
  }
  @Action(AddService)
  public addLuggage(
    { setState }: StateContext<ProfileStateModel>,
    { orderId, newService }: AddService
  ) {
    return this.cabinetApiService.addService(orderId, newService).pipe(
      tap((order) =>
        setState(
          patch({
            items: updateItem<CabinetOrder>(
              (o) => o?.orderId === orderId,
              order
            ),
          })
        )
      )
    );
  }
}
