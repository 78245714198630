import { PaymentCurrency } from "@app-core/enums/currency.enum";
import {PaymentTypeEnum} from "@app-core/enums/payment-type.enum";
import {ChangePassword, ChangeUserInfo, Pagination, PayParameters,} from '@app-core/models';
import {CreateAdditionalService} from "@app-core/entities";

export class InitProfileAction {
  static readonly type = '[Profile] Init';
}
export class GetUserInfo {
  static readonly type = '[Profile] Get User Info';

}
export class FindOrders {
  public static readonly type = '[Profile] Find orders';
}

export class GetOrderById {
  public static readonly type = '[Profile] Get Order By Id';
  constructor(public readonly orderId: number) {}
}
export class ChangeOrdersPage {
  public static readonly type = '[Profile] Change Page';
  constructor(public readonly pagination: Pagination) {}
}
export class ChangeUser {
  public static readonly type = '[Profile] Change User Info';
  constructor(public readonly payload: ChangeUserInfo) {}
}
export class ChangePasswordAction {
  public static readonly type = '[Profile] Change Password';
  constructor(public readonly payload: ChangePassword) {}
}
export class PayAction {
  public static readonly type = '[Profile] Pay';
  constructor(public readonly orderId: number, public readonly method: PaymentTypeEnum,public readonly currency:PaymentCurrency) {}
}
export class PayActionSuccess {
  public static readonly type = '[Profile] Pay Success';
  // constructor(public readonly response: string|PayParameters) {}
}
export class AddService {
  public static readonly type = '[Profile] Add Service';

  constructor(
    public readonly orderId: number,
    public readonly newService: CreateAdditionalService,
  ) {}
}
