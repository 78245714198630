import {CabinetOrder, User} from '@app-core/entities';
import {OrderFilters} from '@app-core/models';

export interface ProfileStateModel {
  items: CabinetOrder[];
  user: User | null;
  totalCount: number;
  pageSize: number;
  pageNumber: number;
  filters: OrderFilters;
  loaded: boolean;
  form: {
    model: any;
    dirty: boolean;
    status: string;
    errors: object;
  };
}

export const PROFILE_STATE_INIT: ProfileStateModel = {
  // items: [],
  user: null,
  items: [],
  totalCount: 0,
  pageSize: 10,
  pageNumber: 1,
  // filters: { statuses: [OrderStatusEnum.New, OrderStatusEnum.Booking] },
  filters: {},
  loaded: false,
  form: {
    model: undefined,
    dirty: false,
    status: '',
    errors: {},
  },
};
