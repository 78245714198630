import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Configs } from '@app-core/configs/configs';
import { Language } from '@app-core/entities';
import { PaymentStatusTypes } from '@app-core/enums';
import { PayParameters } from '@app-core/models';
import { LayoutState } from '@app-states/layout';
import { LayoutStateService } from '@app-states/layout-state.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';
import {MessageService} from "primeng/api";

@Injectable()
export class PayService {
  private readonly window: Window | null;
  constructor(
    private readonly router: Router,
    @Inject(DOCUMENT) private document: Document,
    private readonly authService: AuthService,
    private readonly store: Store,
    private readonly messageService: MessageService
  ) {
    this.window = this.document.defaultView;
  }
  public pay = (payload: {
    redirectTo: string | null;
    payParameters: PayParameters | null;
  }) =>
    new Promise((resolve) => {
      if (payload.redirectTo !== null && this.window) {
        if (!payload.redirectTo.length) {
          resolve('order is fully paid');
          this.messageService.add({
            severity: 'success',
            summary: 'Service Message',
            detail: 'ERRORS.FULLY_PAID',
          });
          return;
        }
        this.window.location.assign(payload.redirectTo);
      } else if (payload.payParameters) {
        this.handlePaymentByCloudPayment(payload.payParameters).then(resolve);
      }
    });
  private handlePaymentByCloudPayment(payload: PayParameters) {
    const language = this.store.selectSnapshot(LayoutState.language);
    const languageCode = language ? language.code : 'en-US';
    const widget = new cp.CloudPayments({
      language: languageCode === 'ka-GE' ? 'en-US' : languageCode,
    });

    return new Promise<string>((resolve, reject) => {
      widget.pay(
        'auth', // или 'charge'
        {
          ...payload,
          publicId: Configs.cloudPayConfig.publicId, //id из личного кабинета
          skin: Configs.cloudPayConfig.skin, //дизайн виджета (необязательно)
          // autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
          // description: payload.description, //назначение
          // amount: payload.amount, //сумма
          // currency: payload., //валюта
          // accountId: payload.accountId, //идентификатор плательщика (необязательно)
          // invoiceId: payload.invoiceId, //номер заказа  (необязательно)
          // email: payload.email, //email плательщика (необязательно)
          // data: payload.data,
        },
        {
          onSuccess: (options: any) => {
            // success
            //действие при успешной оплате
            const isSurcharge = options && options.data === 'surcharge';
            this.handleCloudRedirect(true, options.invoiceId);
            resolve('onSuccess');
            // {
            //   "publicId": "pk_dc520c270aa5a4eab8a02b3d657eb",
            //   "amount": 41180,
            //   "currency": "RUB",
            //   "accountId": "sdsd@sdsd.com",
            //   "description": "Заказ 230119_10; 19.01.2023; Москва - Анталия",
            //   "invoiceId": "94178",
            //   "email": "sdsd@sdsd.com",
            //   "data": "{\"CallbackData\":\"e078606c-87ee-44dc-8236-51026353541d\",\"CloudPayments\":{\"\\u0421ustomerReceipt\":{\"Items\":[{\"Label\":\"\\u0411\\u0440\\u043E\\u043D\\u0438\\u0440\\u043E\\u0432\\u0430\\u043D\\u0438\\u0435 \\u0430\\u0432\\u0438\\u0430\\u0431\\u0438\\u043B\\u0435\\u0442\\u043E\\u0432 \\u043F\\u043E \\u0437\\u0430\\u044F\\u0432\\u043A\\u0435 230119_10\",\"Price\":41180,\"Quantity\":1,\"Amount\":41180,\"Vat\":\"p0\"}],\"TaxationSystem\":\"common\",\"Amounts\":{\"Electronic\":41180,\"AdvancePayment\":0,\"Credit\":0,\"Provision\":0},\"Email\":\"myloveiscinema@gmail.com\",\"Phone\":null}}}",
            //   "skin": "mini",
            //   "autoClose": 3
            // }
          },
          // не всегда приходит, если например смс не ввел верно то не будет тут ничего
          onFail: (reason: any, options: any) => {
            // fail
            //действие при неуспешной оплате
            const isSurcharge = options && options.data === 'surcharge';
            //fixme
            if (reason !== 'User has cancelled' && options) {
              this.handleCloudRedirect(false, options.invoiceId);
            }
            resolve('onFail');
          },
          // приходит раньше всех
          onComplete: (
            paymentResult: {
              code: number;
              email: string;
              message: string;
              success: boolean;
            },
            options: any
          ) => {
            if (paymentResult) {
              // this.router.navigate(['payment-callback'],{queryParams:{status: paymentResult.success?PaymentStatusTypes.Success:PaymentStatusTypes.Failed}})
            }
            // {
            //   code: 3006;
            //   email: 'disel92kk@gmail.com';
            //   message: 'Сервис временно недоступен. Пожалуйста, повторите попытку позже';
            //   success: false;
            // -----------------------
            // }
            // {
            // code:3001;
            // email: "ffdfd@fdf.com";
            // message:"Неверный номер заказа";
            // success:false;
            // }
            // {
            //   "success": true,
            //   "message": null,
            //   "code": "0",
            //   "email": "sdsd@sdsd.com"
            // }
            //// ошибка 3d secure
            // {
            //   "success": false,
            //   "message": null,
            //   "code": "5206",
            //   "email": "sdsd@sdsd.com"
            // }
            //   /{
            //   "publicId": "pk_dc520c270aa5a4eab8a02b3d657eb",
            //     "amount": 41180,
            //     "currency": "RUB",
            //     "accountId": "sdsd@sdsd.com",
            //     "description": "Заказ 230119_10; 19.01.2023; Москва - Анталия",
            //     "invoiceId": "94178",
            //     "email": "sdsd@sdsd.com",
            //     "data": "{\"CallbackData\":\"e078606c-87ee-44dc-8236-51026353541d\",\"CloudPayments\":{\"\\u0421ustomerReceipt\":{\"Items\":[{\"Label\":\"\\u0411\\u0440\\u043E\\u043D\\u0438\\u0440\\u043E\\u0432\\u0430\\u043D\\u0438\\u0435 \\u0430\\u0432\\u0438\\u0430\\u0431\\u0438\\u043B\\u0435\\u0442\\u043E\\u0432 \\u043F\\u043E \\u0437\\u0430\\u044F\\u0432\\u043A\\u0435 230119_10\",\"Price\":41180,\"Quantity\":1,\"Amount\":41180,\"Vat\":\"p0\"}],\"TaxationSystem\":\"common\",\"Amounts\":{\"Electronic\":41180,\"AdvancePayment\":0,\"Credit\":0,\"Provision\":0},\"Email\":\"myloveiscinema@gmail.com\",\"Phone\":null}}}",
            //     "skin": "mini",
            //     "autoClose": 3
            // }
            //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
            //например вызов вашей аналитики Facebook Pixel
          },
        }
      );
    });
  }
  private async handleCloudRedirect(success: boolean, id: string) {
    const isAuth = await this.authService.isAuth();
    if (isAuth) {
      this.router.navigate(['profile/item', id]);
    } else {
      this.router.navigate(['payment-callback'], {
        queryParams: {
          status: success
            ? PaymentStatusTypes.Success
            : PaymentStatusTypes.Failed,
          isSurcharge: false,
        },
      });
    }
  }
}
